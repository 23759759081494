import { render, staticRenderFns } from "./subjects-of-allegation-other-form.vue?vue&type=template&id=7dcbd768&scoped=true"
import script from "./subjects-of-allegation-other-form.vue?vue&type=script&lang=js"
export * from "./subjects-of-allegation-other-form.vue?vue&type=script&lang=js"
import style0 from "./subjects-of-allegation-other-form.vue?vue&type=style&index=0&id=7dcbd768&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dcbd768",
  null
  
)

export default component.exports